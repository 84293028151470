
import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiExpressAdd, apiExpressDetail, apiExpressEdit } from '@/api/setting'
@Component({
    components: {
        MaterialSelect
    }
})
export default class ExpressEdit extends Vue {
    $refs!: { form: any }
    id!: any
    form = {
        name: '',
        icon: '',
        type:1,
        code: '',
        code100: '',
        codebird: '',
        sort: ''
    }
    loading = false
    handleSave() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                const api = this.id ? apiExpressEdit(this.form) : apiExpressAdd(this.form)
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }

    getExpressDetail() {
        this.loading = true
        apiExpressDetail({ id: this.id }).then((res: any) => {
            this.loading = false
            this.form = res
        })
    }

    created() {
        this.id = this.$route.query.id
        this.id && this.getExpressDetail()
    }
}
